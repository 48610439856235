import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';
import { Header } from '../PageTitle_tempHeader/pageTitle';
import Footer from '../Footer/footer';

interface BaseLayoutProps {
  children?: ReactNode;
}
const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      <Header />

      <div style={{ flex: 1 }}>{children || <Outlet />}</div>

      <Footer />
    </div>
  );
};
BaseLayout.propTypes = {
  children: PropTypes.node,
};

export default BaseLayout;
