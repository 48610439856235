import { FirebaseTypeChange } from '../utils/FirebaseTypeChange';

export class TournamentRealTime {
  prevSecond: number;
  lastCheckedTime: Date;
  isProgress: boolean;
  endAt: Date;

  constructor(
    prevSecond: number,
    lastCheckedTime: Date,
    isProgress: boolean,
    endAt: Date
  ) {
    this.prevSecond = prevSecond;
    this.lastCheckedTime = lastCheckedTime;
    this.isProgress = isProgress;
    this.endAt = endAt;
  }
  static fromData(data: any): TournamentRealTime {
    try {
      const prevSecond = FirebaseTypeChange.numberFromData(data['prevSecond']);
      const lastCheckedTime = FirebaseTypeChange.dateFromData(
        data['lastCheckedTime']
      );
      const isProgress = FirebaseTypeChange.booleanFromData(data['isProgress']);
      const endAt = FirebaseTypeChange.dateFromData(data['endAt']);

      return new TournamentRealTime(
        prevSecond,
        lastCheckedTime,
        isProgress,
        endAt
      );
    } catch (e) {
      console.log(`[TournamentRealTime] fromData e:${e}`);
      return TournamentRealTime.empty;
    }
  }
  static fromIndexDBData(data: any): TournamentRealTime {
    try {
      const prevSecond = FirebaseTypeChange.numberFromData(data['prevSecond']);
      const lastCheckedTime = FirebaseTypeChange.dateFromData(
        data['lastCheckedTime']
      );
      const isProgress = FirebaseTypeChange.booleanFromData(data['isProgress']);
      const endAt = FirebaseTypeChange.dateFromData(data['endAt']);

      return new TournamentRealTime(
        prevSecond,
        lastCheckedTime,
        isProgress,
        endAt
      );
    } catch (e) {
      console.log(`[TournamentRealTime] fromData e:${e}`);
      return TournamentRealTime.empty;
    }
  }

  static get empty() {
    return new TournamentRealTime(0, new Date(0), false, new Date(0));
  }

  get clone() {
    return new TournamentRealTime(
      this.prevSecond,
      this.lastCheckedTime,
      this.isProgress,
      this.endAt
    );
  }

  get toIndexedDB() {
    return {
      prevSecond: this.prevSecond,
      lastCheckedTime: this.lastCheckedTime,
      isProgress: this.isProgress,
      endAt: this.endAt,
    };
  }
  get toMap() {
    return {
      prevSecond: this.prevSecond,
      lastCheckedTime: this.lastCheckedTime,
      isProgress: this.isProgress,
      endAt: this.endAt,
    };
  }
}
