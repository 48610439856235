import { DataService } from "src/data/dataService";
import { TournamentInfo } from "src/domain/tournament/TournamentInfo.model";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export function TestPage({}: {}) {
  const clickFunc = async () => {
    // const pubList = ["a129cvi34dhi398", "bb290d7hsudofkj"];
    // const firestore = firebase.firestore();
    // const tournaCol = firestore.collection("wwp_tournaments");
    // for (const pId of pubList) {
    //   const ts = await DataService.fetchWholeTournamentInfo(pId);
    //   for (const oneT of ts) {
    //     const tDoc = tournaCol.doc(oneT.id);
    //     await tDoc.set(oneT.toMap);
    //   }
    // }
  };

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <button onClick={clickFunc}>테스트 버튼</button>
    </div>
  );
}
