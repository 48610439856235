import { Timestamp } from "firebase/firestore";
import { CostAndChip } from "./CostAndChip.interface";
import {
  ADDON_KEY,
  BUYIN_KEY,
  REBUYIN_KEY,
  REENTRY_KEY,
} from "../const/entry_const";
import { FirebaseTypeChange } from "../utils/FirebaseTypeChange";
import { isJSDocNullableType } from "typescript";
import { GeneralData } from "./GeneralData.model";

export class EntryData {
  entryList: CostAndChip[];
  remainPlayer: number;

  constructor(entryList: CostAndChip[], remainPlayer: number) {
    this.entryList = entryList;
    this.remainPlayer = remainPlayer;
  }

  static fromData(data: any): EntryData {
    try {
      const entryList = FirebaseTypeChange.listFromData(data["entryList"]);
      const remainPlayer = FirebaseTypeChange.numberFromData(
        data["remainPlayer"]
      );

      return new EntryData(entryList, remainPlayer);
    } catch (error) {
      console.log(`[EntryData Model] fromData e: ${error}`);
      return EntryData.empty;
    }
  }

  static fromIndexedDB(data: any): EntryData {
    try {
      const entryList = data["entryList"];
      const remainPlayer = data["remainPlayer"];

      return new EntryData(entryList, remainPlayer);
    } catch (error) {
      console.log(`[EntryData Model] fromIndexedDB e: ${error}`);
      return EntryData.empty;
    }
  }

  get toMap() {
    return {
      entryList: this.entryList,
      remainPlayer: this.remainPlayer,
    };
  }

  get toIndexedDB() {
    return {
      entryList: this.entryList,
      remainPlayer: this.remainPlayer,
    };
  }

  static get empty() {
    return new EntryData([], 0);
  }

  get clone() {
    return new EntryData(
      this.entryList.map((e) => ({ ...e })),
      this.remainPlayer
    );
  }

  static getTotalPlayer(entries: CostAndChip[]): number {
    let count = 0;
    // for (const cac of entries) {
    //   if (cac.title === BUYIN_KEY || cac.title === REENTRY_KEY) {
    //     count += cac.num;
    //   }
    // }
    for (const cac of entries) {
      if (cac.title === BUYIN_KEY) {
        count += cac.num;
      }
    }
    return count;
  }

  static getTotalCostAndChip(entries: CostAndChip[]): CostAndChip {
    let totalCost = 0;
    let totalChip = 0;

    for (const cac of entries) {
      totalCost += cac.cost;
      totalChip += cac.chip;
    }

    return {
      title: "",
      num: 0,
      cost: totalCost,
      chip: totalChip,
    };
  }

  static getCostAndChip(
    entries: CostAndChip[],
    title: string
  ): CostAndChip | null {
    for (const oneCost of entries) {
      if (oneCost.title === title) {
        return oneCost;
      }
    }
    return null;
  }

  static getRebuyInAllCount(entries: CostAndChip[]): number {
    let count = 0;

    for (const cac of entries) {
      if (cac.title.includes(REBUYIN_KEY)) {
        count += cac.num;
      }
    }

    return count;
  }

  static getReEntryCount(entries: CostAndChip[]): number {
    let count = 0;

    for (const cac of entries) {
      if (cac.title.includes(REENTRY_KEY)) {
        count += cac.num;
      }
    }

    return count;
  }
}
