import { Timestamp } from "firebase/firestore";
import { CostAndChip } from "./CostAndChip.interface";
import {
  ADDON_KEY,
  BUYIN_KEY,
  REBUYIN_KEY,
  REENTRY_KEY,
} from "../const/entry_const";
import { FirebaseTypeChange } from "../utils/FirebaseTypeChange";

export class GeneralData {
  gameName: string;
  subTitle: string;
  startTime: Date;
  note: string; // 진행정보
  basicNote: string; // 기본정보
  isPrivate: boolean;
  addOnCost: CostAndChip;
  buyInCost: CostAndChip;
  reEntryCost: CostAndChip;
  rebuyCosts: CostAndChip[];
  addedChip: number;
  tournaSkin: number;

  constructor(
    gameName: string,
    subTitle: string,
    startTime: Date,
    note: string,
    basicNote: string,
    isPrivate: boolean,
    addOnCost: CostAndChip,
    buyInCost: CostAndChip,
    reEntryCost: CostAndChip,
    rebuyCosts: CostAndChip[],
    addedChip: number,
    tournaSkin: number
  ) {
    this.gameName = gameName;
    this.subTitle = subTitle;
    this.startTime = startTime;
    this.note = note;
    this.basicNote = basicNote;
    this.isPrivate = isPrivate;
    this.addOnCost = addOnCost;
    this.buyInCost = buyInCost;
    this.reEntryCost = reEntryCost;
    this.rebuyCosts = rebuyCosts;
    this.addedChip = addedChip;
    this.tournaSkin = tournaSkin;
  }

  static fromData(data: any): GeneralData {
    try {
      const gameName = FirebaseTypeChange.stringFromData(data["gameName"]);
      const subTitle = FirebaseTypeChange.stringFromData(data["subTitle"]);
      const startTime = FirebaseTypeChange.dateFromData(data["startTime"]);
      const note = FirebaseTypeChange.stringFromData(data["note"]);
      const basicNote = FirebaseTypeChange.stringFromData(data["basicNote"]);
      const isPrivate = FirebaseTypeChange.booleanFromData(data["isPrivate"]);
      const addOnCost = FirebaseTypeChange.anyFromData(data["addOnCost"]);
      const buyInCost = FirebaseTypeChange.anyFromData(data["buyInCost"]);
      const reEntryCost = FirebaseTypeChange.anyFromData(data["reEntryCost"]);
      const rebuyCosts = FirebaseTypeChange.listFromData(data["rebuyCosts"]);
      const addedChip = FirebaseTypeChange.numberFromData(data["addedChip"]);
      const tournaSkin = FirebaseTypeChange.numberFromData(data["tournaSkin"]);

      return new GeneralData(
        gameName,
        subTitle,
        startTime,
        note,
        basicNote,
        isPrivate,
        addOnCost,
        buyInCost,
        reEntryCost,
        rebuyCosts,
        addedChip,
        tournaSkin
      );
    } catch (error) {
      console.log(`[GeneralData Model] fromData e: ${error}`);
      return GeneralData.empty;
    }
  }

  static fromIndexedDB(data: any): GeneralData {
    try {
      const gameName = data["gameName"];
      const subTitle = data["subTitle"];
      const startTime = data["startTime"];
      const note = data["note"];
      const basicNote = data["basicNote"];
      const isPrivate = data["isPrivate"];
      const addOnCost = data["addOnCost"];
      const buyInCost = data["buyInCost"];
      const reEntryCost = data["reEntryCost"];
      const rebuyCosts = data["rebuyCosts"];
      const addedChip = data["addedChip"];
      const tournaSkin = data["tournaSkin"];

      return new GeneralData(
        gameName,
        subTitle,
        startTime,
        note,
        basicNote,
        isPrivate,
        addOnCost,
        buyInCost,
        reEntryCost,
        rebuyCosts,
        addedChip,
        tournaSkin
      );
    } catch (error) {
      console.log(`[GeneralData Model] fromIndexedDB e: ${error}`);
      return GeneralData.empty;
    }
  }

  get toMap() {
    return {
      gameName: this.gameName,
      subTitle: this.subTitle,
      startTime: this.startTime,
      note: this.note,
      basicNote: this.basicNote,
      isPrivate: this.isPrivate,
      addOnCost: this.addOnCost,
      buyInCost: this.buyInCost,
      reEntryCost: this.reEntryCost,
      rebuyCosts: this.rebuyCosts,
      addedChip: this.addedChip,
      tournaSkin: this.tournaSkin,
    };
  }

  get toIndexedDB() {
    return {
      gameName: this.gameName,
      subTitle: this.subTitle,
      startTime: this.startTime,
      note: this.note,
      basicNote: this.basicNote,
      isPrivate: this.isPrivate,
      addOnCost: this.addOnCost,
      buyInCost: this.buyInCost,
      reEntryCost: this.reEntryCost,
      rebuyCosts: this.rebuyCosts,
      addedChip: this.addedChip,
      tournaSkin: this.tournaSkin,
    };
  }
  static get empty() {
    return new GeneralData(
      "",
      "",
      new Date(),
      "",
      "",
      true,
      { title: ADDON_KEY, num: 0, cost: 0, chip: 0 },
      { title: BUYIN_KEY, num: 0, cost: 0, chip: 0 },
      { title: REENTRY_KEY, num: 0, cost: 0, chip: 0 },
      [{ title: REBUYIN_KEY, num: 0, cost: 0, chip: 0 }],
      0,
      0
    );
  }

  get clone() {
    return new GeneralData(
      this.gameName,
      this.subTitle,
      this.startTime,
      this.note,
      this.basicNote,
      this.isPrivate,
      { ...this.addOnCost },
      { ...this.buyInCost },
      { ...this.reEntryCost },
      this.rebuyCosts.map((rc) => ({ ...rc })),
      this.addedChip,
      this.tournaSkin
    );
  }
}
