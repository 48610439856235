import { mockData } from '../mock-data-v1';
import { FirebaseTypeChange } from '../utils/FirebaseTypeChange';

export class LevelData {
  prevSound: number | null;
  rebuyableLevel: number | null;
  blindList: BlindLevel[];

  constructor(
    prevSound: number | null,
    rebuyableLevel: number | null,
    blindList: BlindLevel[]
  ) {
    this.prevSound = prevSound;
    this.rebuyableLevel = rebuyableLevel;
    this.blindList = blindList;
  }

  static fromData(data: any): LevelData {
    try {
      const prevSound = FirebaseTypeChange.numberNullFromData(
        data['prevSound']
      );
      const rebuyableLevel = FirebaseTypeChange.numberNullFromData(
        data['rebuyableLevel']
      );
      const blindList = FirebaseTypeChange.listFromData(data['blindList']);

      return new LevelData(prevSound, rebuyableLevel, blindList);
    } catch (error) {
      console.log(`[LevelData Model] fromData e: ${error}`);
      return LevelData.empty;
    }
  }

  static fromIndexedDB(data: any): LevelData {
    try {
      const prevSound = data['prevSound'];
      const rebuyableLevel = data['rebuyableLevel'];
      const blindList = data['blindList'];
      return new LevelData(prevSound, rebuyableLevel, blindList);
    } catch (error) {
      console.log(`[LevelData Model] fromIndexedDB e: ${error}`);
      return LevelData.empty;
    }
  }

  get toMap() {
    return {
      prevSound: this.prevSound,
      rebuyableLevel: this.rebuyableLevel,
      blindList: this.blindList,
    };
  }

  get toIndexedDB() {
    return {
      prevSound: this.prevSound,
      rebuyableLevel: this.rebuyableLevel,
      blindList: this.blindList,
    };
  }
  static get empty() {
    return new LevelData(null, null, []);
  }
  static get mokEmpty() {
    return new LevelData(null, null, mockData.blindList);
  }
  get clone() {
    return new LevelData(
      this.prevSound,
      this.rebuyableLevel,
      this.blindList.map((b) => ({ ...b }))
    );
  }
}

export interface BlindLevel {
  isBreak: boolean;
  level: number;
  bigBlind: number;
  smallBlind: number;
  ante: number;
  second: number; // 해당 레벨의 시간
}
