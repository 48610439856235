export const mockData = {
  prizeStructure: [
    {
      rank: '1',
      prize: '170 매장이용권',
    },
    {
      rank: ' 2',
      prize: '130 매장이용권',
    },
    {
      rank: '3',
      prize: '100 매장이용권',
    },
    {
      rank: '4',
      prize: '70 매장이용권',
    },
    {
      rank: '5',
      prize: '40 매장이용권',
    },
    {
      rank: '6',
      prize: '20 매장이용권',
    },
  ],
  blindList: [
    {
      isBreak: false,
      level: 1,
      bigBlind: 200,
      smallBlind: 100,
      ante: 200,
      second: 600,
    },
    {
      isBreak: false,
      level: 2,
      bigBlind: 400,
      smallBlind: 200,
      ante: 400,
      second: 600,
    },
    {
      isBreak: false,
      level: 3,
      bigBlind: 600,
      smallBlind: 300,
      ante: 600,
      second: 600,
    },
    {
      isBreak: false,
      level: 4,
      bigBlind: 800,
      smallBlind: 400,
      ante: 800,
      second: 600,
    },
    {
      isBreak: false,
      level: 5,
      bigBlind: 1000,
      smallBlind: 500,
      ante: 1000,
      second: 600,
    },
    {
      isBreak: true,
      level: 0,
      bigBlind: 0,
      smallBlind: 0,
      ante: 0,
      second: 600,
    },
    {
      isBreak: false,
      level: 6,
      bigBlind: 1200,
      smallBlind: 600,
      ante: 1200,
      second: 600,
    },
    {
      isBreak: false,
      level: 7,
      bigBlind: 1600,
      smallBlind: 800,
      ante: 1600,
      second: 600,
    },
    {
      isBreak: false,
      level: 8,
      bigBlind: 2000,
      smallBlind: 1000,
      ante: 2000,
      second: 600,
    },
    {
      isBreak: false,
      level: 9,
      bigBlind: 2400,
      smallBlind: 1200,
      ante: 2400,
      second: 600,
    },
    {
      isBreak: false,
      level: 10,
      bigBlind: 3000,
      smallBlind: 1500,
      ante: 3000,
      second: 600,
    },
    {
      isBreak: true,
      level: 0,
      bigBlind: 0,
      smallBlind: 0,
      ante: 0,
      second: 600,
    },
    {
      isBreak: false,
      level: 11,
      bigBlind: 4000,
      smallBlind: 2000,
      ante: 4000,
      second: 600,
    },
    {
      isBreak: false,
      level: 12,
      bigBlind: 6000,
      smallBlind: 3000,
      ante: 6000,
      second: 600,
    },
    {
      isBreak: false,
      level: 13,
      bigBlind: 8000,
      smallBlind: 4000,
      ante: 8000,
      second: 600,
    },
    {
      isBreak: false,
      level: 14,
      bigBlind: 10000,
      smallBlind: 5000,
      ante: 10000,
      second: 600,
    },
    {
      isBreak: false,
      level: 15,
      bigBlind: 12000,
      smallBlind: 6000,
      ante: 12000,
      second: 600,
    },
    {
      isBreak: true,
      level: 0,
      bigBlind: 0,
      smallBlind: 0,
      ante: 0,
      second: 600,
    },
    {
      isBreak: false,
      level: 16,
      bigBlind: 14000,
      smallBlind: 7000,
      ante: 14000,
      second: 600,
    },
    {
      isBreak: false,
      level: 17,
      bigBlind: 16000,
      smallBlind: 8000,
      ante: 16000,
      second: 600,
    },
    {
      isBreak: false,
      level: 18,
      bigBlind: 20000,
      smallBlind: 10000,
      ante: 20000,
      second: 600,
    },
    {
      isBreak: false,
      level: 19,
      bigBlind: 30000,
      smallBlind: 15000,
      ante: 30000,
      second: 600,
    },
    {
      isBreak: false,
      level: 20,
      bigBlind: 40000,
      smallBlind: 20000,
      ante: 40000,
      second: 600,
    },
    {
      isBreak: true,
      level: 0,
      bigBlind: 0,
      smallBlind: 0,
      ante: 0,
      second: 600,
    },
    {
      isBreak: false,
      level: 21,
      bigBlind: 60000,
      smallBlind: 30000,
      ante: 60000,
      second: 600,
    },
    {
      isBreak: false,
      level: 22,
      bigBlind: 80000,
      smallBlind: 40000,
      ante: 80000,
      second: 600,
    },
    {
      isBreak: false,
      level: 23,
      bigBlind: 100000,
      smallBlind: 50000,
      ante: 100000,
      second: 600,
    },
    {
      isBreak: false,
      level: 24,
      bigBlind: 120000,
      smallBlind: 60000,
      ante: 120000,
      second: 600,
    },
    {
      isBreak: false,
      level: 25,
      bigBlind: 160000,
      smallBlind: 80000,
      ante: 160000,
      second: 600,
    },
    {
      isBreak: true,
      level: 0,
      bigBlind: 0,
      smallBlind: 0,
      ante: 0,
      second: 600,
    },
    {
      isBreak: false,
      level: 26,
      bigBlind: 200000,
      smallBlind: 100000,
      ante: 200000,
      second: 600,
    },
    {
      isBreak: false,
      level: 27,
      bigBlind: 300000,
      smallBlind: 150000,
      ante: 300000,
      second: 600,
    },
    {
      isBreak: false,
      level: 28,
      bigBlind: 400000,
      smallBlind: 200000,
      ante: 400000,
      second: 600,
    },
    {
      isBreak: false,
      level: 29,
      bigBlind: 600000,
      smallBlind: 300000,
      ante: 600000,
      second: 600,
    },
    {
      isBreak: false,
      level: 30,
      bigBlind: 800000,
      smallBlind: 400000,
      ante: 800000,
      second: 600,
    },
  ],
};
