// import { OpenDay } from "./Days.model";
// import { Game } from "./Game.model";
// import { GameTemplate } from "./GameTemplate.model";

import { PubBasic } from "./PubBasic.model";
import { FirebaseTypeChange } from "../utils/FirebaseTypeChange";
import { TournamentInfo } from "../tournament/TournamentInfo.model";
import { PubSkinStorage } from "./tournament/PubSkinStorage.model";

export type Links = {
  name: string;
  url: string;
};

export class Pub {
  readonly id: string;
  ownerId: string;
  basicInfo: PubBasic;
  games: TournamentInfo[];
  pubSkin: PubSkinStorage;

  constructor(
    id: string,
    ownerId: string,
    basicInfo: PubBasic,
    games: TournamentInfo[],
    pubSkin: PubSkinStorage
  ) {
    this.id = id;
    this.ownerId = ownerId;
    this.basicInfo = basicInfo;
    this.games = games;
    this.pubSkin = pubSkin;
  }

  get clone() {
    return new Pub(
      this.id,
      this.ownerId,
      this.basicInfo.clone,
      this.games.map((game) => game.clone),
      this.pubSkin.clone
    );
  }

  get toMap() {
    return {
      id: this.id,
      ownerId: this.ownerId,
      ...this.basicInfo.toMap,
      pubSkin: this.pubSkin.toMap,
      // games: [] sub-col
    };
  }

  static fromData(data: any): Pub {
    try {
      const id = FirebaseTypeChange.stringFromData(data["id"]); // string;
      const ownerId = FirebaseTypeChange.stringFromData(data["ownerId"]); // string;
      const basicInfo = PubBasic.fromData(data); // PubBasic;
      const pubSkin = PubSkinStorage.fromData(data["pubSkin"]);
      // const games = TournamentInfo.fromListData(data["games"]); // TournamentInfo[];

      return new Pub(id, ownerId, basicInfo, [], pubSkin);
    } catch (error) {
      console.log(`[Pub Model] fromData e: ${error}`);

      return Pub.empty;
    }
  }

  static get empty() {
    return new Pub("", "", PubBasic.empty, [], PubSkinStorage.empty);
  }

  static initPub(id: string, ownerId: string) {
    return {
      id: id,
      ownerId: ownerId,
      name: "",
      description: "",
      phone: "",
      lat: 33.5064929,
      lon: 126.4864723,
      addressBasic: "",
      addressDetail: "",
      links: [
        { name: "kakao", url: "" },
        { name: "insta", url: "" },
      ],
      photos: [],
      openDays: [],
      readyTime: Date,
      games: [],
      pubSkin: PubSkinStorage.empty.toMap,
    };
  }
}
