import { FirebaseTypeChange } from "src/domain/utils/FirebaseTypeChange";

export class PubSkinStorage {
  backgroundImgs: string[];
  templates: SkinTemplate[];

  constructor(backgroundImgs: string[], templates: SkinTemplate[]) {
    this.backgroundImgs = backgroundImgs;
    this.templates = templates;
  }
  static fromData(data: any): PubSkinStorage {
    try {
      const backgroundImgs = FirebaseTypeChange.listFromData(
        data["backgroundImgs"]
      );
      const templates = SkinTemplate.fromListData(data["templates"]);
      return new PubSkinStorage(backgroundImgs, templates);
    } catch (e) {
      console.log(`[PubSkinStorage] fromData e: ${e}`);
      return PubSkinStorage.empty;
    }
  }
  get toMap() {
    return {
      backgroundImgs: this.backgroundImgs,
      templates: this.templates.map((v) => v.toMap),
    };
  }

  get clone() {
    return new PubSkinStorage(
      this.backgroundImgs.map((v) => v as string),
      this.templates.map((v) => v.clone)
    );
  }

  static get empty(): PubSkinStorage {
    return new PubSkinStorage([], []);
  }
}

class SkinTemplate {
  title: string;
  backgroundImg: string;
  skinData: any | null; // Map

  constructor(title: string, backgroundImg: string, skinData: any) {
    this.title = title;
    this.backgroundImg = backgroundImg;
    this.skinData = skinData;
  }
  static fromData(data: any): SkinTemplate {
    try {
      const title = FirebaseTypeChange.stringFromData(data["title"]);
      const backgroundImg = FirebaseTypeChange.stringFromData(
        data["backgroundImg"]
      );
      const skinData = FirebaseTypeChange.anyNullFromData(data["skinData"]);
      return new SkinTemplate(title, backgroundImg, skinData);
    } catch (e) {
      console.log(`[SkinTemplate] fromData e: ${e}`);
      return SkinTemplate.empty;
    }
  }

  static fromListData(data: any): SkinTemplate[] {
    try {
      let temp: SkinTemplate[] = [];
      for (const v of data) {
        temp.push(SkinTemplate.fromData(v));
      }
      return temp;
    } catch (e) {
      console.log(`[SkinTemplate] fromListData e: ${e}`);
      return [];
    }
  }
  get toMap() {
    return {
      title: this.title,
      backgroundImg: this.backgroundImg,
      skinData: this.skinData,
    };
  }
  get clone() {
    return new SkinTemplate(this.title, this.backgroundImg, this.skinData);
  }

  static get empty(): SkinTemplate {
    return new SkinTemplate("", "", null);
  }
}
