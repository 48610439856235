import { FirebaseTypeChange } from "src/domain/utils/FirebaseTypeChange";

export interface PlayerScore {
  name: string;
  score: number;
}

export class TournamentResult {
  readonly id: string;
  prize: string;
  totalPlayer: number;
  tournaDate: Date;
  tournaTitle: string;
  players: PlayerScore[];

  constructor(
    id: string,
    prize: string,
    totalPlayer: number,
    tournaDate: Date,
    tournaTitle: string,
    players: PlayerScore[]
  ) {
    this.id = id;
    this.prize = prize;
    this.totalPlayer = totalPlayer;
    this.tournaDate = tournaDate;
    this.tournaTitle = tournaTitle;
    this.players = players;
  }

  static fromData(data: any): TournamentResult {
    try {
      const id: string = FirebaseTypeChange.stringFromData(data["id"]);
      const prize: string = FirebaseTypeChange.stringFromData(data["prize"]);
      const totalPlayer: number = FirebaseTypeChange.numberFromData(
        data["totalPlayer"]
      );
      const tournaDate: Date = FirebaseTypeChange.dateFromData(
        data["tournaDate"]
      );
      const tournaTitle: string = FirebaseTypeChange.stringFromData(
        data["tournaTitle"]
      );
      const players: PlayerScore[] = FirebaseTypeChange.listFromData(
        data["players"]
      );

      return new TournamentResult(
        id,
        prize,
        totalPlayer,
        tournaDate,
        tournaTitle,
        players
      );
    } catch (error) {
      console.log(`[TournamentResult] fromData e:${error}`);
      return TournamentResult.empty;
    }
  }

  get toMap() {
    return {
      id: this.id,
      prize: this.prize,
      totalPlayer: this.totalPlayer,
      tournaDate: this.tournaDate,
      tournaTitle: this.tournaTitle,
      players: this.players,
    };
  }

  static get empty() {
    return new TournamentResult("", "", 0, new Date(0), "", []);
  }
  static getPlayerScore(data: TournamentResult[]): PlayerScore[] {
    let newPlayerScore: PlayerScore[] = [];

    data.forEach((data_v) => {
      data_v.players.forEach((p_v) => {
        const existingPlayer = newPlayerScore.find(
          (player) => player.name === p_v.name
        );

        if (existingPlayer) {
          existingPlayer.score += p_v.score;
        } else {
          newPlayerScore.push(p_v);
        }
      });
    });

    newPlayerScore.sort((a, b) => b.score - a.score);
    return newPlayerScore;
  }
}
