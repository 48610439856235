// 모든페이지 아래로 끝까지 스크롤하면 나오는 파트임당

import { Box, Container, Link, Typography, styled } from "@mui/material";

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

export function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display={{ xs: "block", md: "flex" }}
        border={"1px"}
        alignItems="center"
        textAlign={{ xs: "center", md: "left" }}
        justifyContent="space-between"
      >
        <Box>
          <Typography className="text-white" variant="subtitle1">
            &copy; 2023 - Reraise Enterprise
          </Typography>
        </Box>
        <Typography
          className="text-white"
          sx={{
            pt: { xs: 2, md: 0 },
          }}
          variant="subtitle1"
        >
          Crafted by{" reraise Team"}
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
