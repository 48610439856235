import { GeneralData } from './GeneralData.model';
import { PrizeData } from './PrizeData.model';
import { EntryData } from './EntryData.model';
import { FirebaseTypeChange } from '../utils/FirebaseTypeChange';
import {
  ADDON_KEY,
  BUYIN_KEY,
  REBUYIN_KEY,
  REENTRY_KEY,
} from '../const/entry_const';
import { TournamentRealTime } from './TournamentRealTime.model';
import { BlindLevel, LevelData } from './LevelData.model';
import { TournamentSkinData } from './TournamentSkinData.model';

export enum GameStatus {
  notStart,
  registerAble,
  endRegister,
  none,
}

export class TournamentInfo {
  readonly id: string;
  pubId: string; // 지점 id
  realTimeData: TournamentRealTime;
  generalData: GeneralData;
  prizeData: PrizeData;
  entryData: EntryData;
  levelData: LevelData;
  skinData: TournamentSkinData;

  constructor(
    id: string,
    pubId: string,
    realTimeData: TournamentRealTime,
    generalData: GeneralData,
    prizeData: PrizeData,
    entryData: EntryData,
    levelData: LevelData,
    skinData: TournamentSkinData
  ) {
    this.id = id;
    this.pubId = pubId;
    this.realTimeData = realTimeData;
    this.generalData = generalData;
    this.prizeData = prizeData;
    this.entryData = entryData;
    this.levelData = levelData;
    this.skinData = skinData;
  }

  static fromListData(data: any): TournamentInfo[] {
    try {
      let temp: TournamentInfo[] = [];

      for (const oneData in data) {
        temp.push(TournamentInfo.fromData(oneData));
      }

      return temp;
    } catch (e) {
      console.log(`[TournamentInfo Model] fromListData e: ${e}`);
      return [];
    }
  }

  static fromData(data: any): TournamentInfo {
    try {
      const id = FirebaseTypeChange.stringFromData(data['id']); // string;
      const pubId = FirebaseTypeChange.stringFromData(data['pubId']); // string;
      const realTimeData = TournamentRealTime.fromData(data);
      const generalData = GeneralData.fromData(data); // GeneralData;
      const prizeData = PrizeData.fromData(data); // PrizeData;
      const entryData = EntryData.fromData(data); // EntryData;
      const levelData = LevelData.fromData(data); // LevelData
      const skinData = TournamentSkinData.fromData(data['skinData']); // SkinData

      return new TournamentInfo(
        id,
        pubId,
        realTimeData,
        generalData,
        prizeData,
        entryData,
        levelData,
        skinData
      );
    } catch (error) {
      console.log(`[TournamentInfo Model] fromData e: ${error}`);
      return TournamentInfo.empty;
    }
  }

  static fromIndexedDB(data: any): TournamentInfo {
    try {
      const id = data['id'];
      const pubId = data['pubId'];
      const realTimeData = TournamentRealTime.fromIndexDBData(data); // not store in indexedDB
      const generalData = GeneralData.fromIndexedDB(data);
      const prizeData = PrizeData.fromIndexedDB(data);
      const entryData = EntryData.fromIndexedDB(data); // not store in indexedDB
      const levelData = LevelData.fromData(data); // LevelData
      const skinData = TournamentSkinData.fromData(data['skinData']); // LevelData

      return new TournamentInfo(
        id,
        pubId,
        realTimeData,
        generalData,
        prizeData,
        entryData,
        levelData,
        skinData
      );
    } catch (error) {
      console.log(`[TournamentInfo Model] fromIndexedDB e: ${error}`);
      return TournamentInfo.empty;
    }
  }

  get toIndexedDB() {
    return {
      indexedId: TournamentInfo.indexedId(this.id, this.pubId),
      id: this.id,
      pubId: this.pubId,
      ...this.realTimeData.toIndexedDB, // not store in indexedDB
      ...this.generalData.toIndexedDB,
      ...this.prizeData.toIndexedDB,
      ...this.entryData.toIndexedDB, // not store in indexedDB
      ...this.levelData.toIndexedDB,
    };
  }

  static indexedId(tId: string, pubId: string) {
    return `${tId}-${pubId}`;
  }

  get toMap() {
    return {
      id: this.id,
      pubId: this.pubId,
      skinData: this.skinData.toMap,
      ...this.realTimeData.toMap,
      ...this.generalData.toMap,
      ...this.prizeData.toMap,
      ...this.entryData.toMap,
      ...this.levelData.toMap,
    };
  }

  get toRealTimeMap() {
    return {
      id: this.id,
      pubId: this.pubId,
      ...this.realTimeData.toMap,
      ...this.entryData.toMap,
    };
  }
  get toNoTimeMap() {
    return {
      id: this.id,
      pubId: this.pubId,
      skinData: this.skinData.toMap,
      ...this.generalData.toMap,
      ...this.prizeData.toMap,
      ...this.levelData.toMap,
    };
  }

  static get empty() {
    return new TournamentInfo(
      '',
      '',
      TournamentRealTime.empty,
      GeneralData.empty,
      PrizeData.empty,
      EntryData.empty,
      LevelData.empty,
      TournamentSkinData.basic
    );
  }
  static get mokEmpty() {
    return new TournamentInfo(
      'NOID',
      'NOPUBID',
      TournamentRealTime.empty,
      GeneralData.empty,
      PrizeData.empty,
      EntryData.empty,
      LevelData.mokEmpty,
      TournamentSkinData.basic
    );
  }

  get clone() {
    return new TournamentInfo(
      this.id,
      this.pubId,
      this.realTimeData.clone,
      this.generalData.clone,
      this.prizeData.clone,
      this.entryData.clone,
      this.levelData.clone,
      this.skinData.clone
    );
  }

  static initData(res: string, pubId: string, blindList: BlindLevel[]) {
    return {
      id: res,
      pubId: pubId,
      prevSecond: 0,
      lastCheckedTime: new Date(),
      isProgress: false,
      endAt: new Date(),
      gameName: '토너먼트',
      startTime: new Date(Date.now() - 10000),
      note: '진행정보',
      basicNote: '기본정보',
      isPrivate: true,
      addOnCost: { title: ADDON_KEY, num: 0, cost: 0, chip: 0 },
      buyInCost: { title: BUYIN_KEY, num: 0, cost: 0, chip: 0 },
      reEntryCost: { title: REENTRY_KEY, num: 0, cost: 0, chip: 0 },
      rebuyCosts: [{ title: `${REBUYIN_KEY}rebuy`, num: 0, cost: 0, chip: 0 }],
      addedChip: 0,
      tournaSkin: 0,
      totalPrize: '',
      moneyIn: 0,
      prizeStructure: [],
      entryList: [
        { title: ADDON_KEY, num: 0, cost: 0, chip: 0 },
        { title: BUYIN_KEY, num: 0, cost: 0, chip: 0 },
        { title: REENTRY_KEY, num: 0, cost: 0, chip: 0 },
        { title: REBUYIN_KEY, num: 0, cost: 0, chip: 0 },
      ],
      remainPlayer: 0,
      prevSound: null,
      rebuyableLevel: null,
      blindList: blindList,
      skinData: TournamentSkinData.basic.toMap,
    };
  }

  static getIndex(t: TournamentInfo, prevSecond: number): number {
    const blindList = t.levelData.blindList ?? [];
    let temp = prevSecond;
    let index = -1;
    for (var b of blindList) {
      if (temp >= 0) {
        temp -= Number(b.second);
        index++;
      } else {
        break;
      }
    }
    return Math.max(index, 0);
  }

  get totalBlindTime() {
    let totalBlindTime = 0;
    for (const blind of this.levelData.blindList) {
      totalBlindTime += Number(blind.second);
    }
    return totalBlindTime;
  }

  getStatus(nowTime: Date): GameStatus {
    if (nowTime.getTime() < this.generalData.startTime.getTime()) {
      return GameStatus.notStart;
    }

    if (
      this.realTimeData.endAt.getTime() >= this.generalData.startTime.getTime()
    ) {
      return GameStatus.none;
    }

    const realSecond = this.getRealSecond(nowTime);
    if (realSecond >= this.totalBlindTime) {
      return GameStatus.none;
    }
    if (this.getRemainRebuyableTime(nowTime) > 0) {
      return GameStatus.registerAble;
    }

    return GameStatus.endRegister;
  }
  getRemainRebuyableTime(nowTime: Date): number {
    try {
      let remainRebuyTime = 0;

      if (this.id !== '') {
        if (
          this.levelData.rebuyableLevel === null
          // || tournament.entryData.remainEntry === null
        ) {
          remainRebuyTime = 0;
        } else {
          let totalRebuyAbleSecond = 0;

          for (
            let index = 0;
            index < this.levelData.blindList.length;
            index++
          ) {
            if (
              !this.levelData.blindList[index].isBreak &&
              this.levelData.blindList[index].level >
                this.levelData.rebuyableLevel
            ) {
              break;
            } else {
              totalRebuyAbleSecond += Number(
                this.levelData.blindList[index].second
              );
            }
          }
          remainRebuyTime = totalRebuyAbleSecond - this.getRealSecond(nowTime);
        }
      }
      return Math.max(remainRebuyTime, 0);
    } catch (e) {
      console.log(`[TournamentInfo] getRemainRebuyableTime e:${e}`);
      return 0;
    }
  }

  getRemainTime(nowTime: Date): number {
    var tempNum = -this.getRealSecond(nowTime);
    for (var b of this.levelData.blindList ?? []) {
      tempNum = tempNum + Number(b.second);
      if (tempNum > 0) {
        break;
      }
    }
    return Math.max(0, tempNum);
  }
  getRemainBreakTime(nowTime: Date): number {
    try {
      var remainBreakTime = -this.getRealSecond(nowTime);
      for (var b of this.levelData.blindList ?? []) {
        remainBreakTime += Number(b.second);
        if (remainBreakTime > 0 && b.isBreak) {
          remainBreakTime -= Number(b.second);
          if (remainBreakTime < 0) {
            remainBreakTime = 0;
          }
          break;
        }
      }
      return Math.max(0, remainBreakTime);
    } catch (e) {
      return 0;
    }
  }

  getRealSecond(nowTime: Date): number {
    if (this.realTimeData.isProgress) {
      return Math.floor(
        (this.realTimeData.prevSecond * 1000 +
          nowTime.getTime() -
          this.realTimeData.lastCheckedTime.getTime()) /
          1000
      );
    }
    return this.realTimeData.prevSecond;
  }
}
