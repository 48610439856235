import { FirebaseTypeChange } from "../utils/FirebaseTypeChange";

export const dayList = ["월", "화", "수", "목", "금", "토", "일"];
export class OpenDays {
  day: string;
  start: string;
  duration: number;
  closed: boolean;

  constructor(day: string, start: string, duration: number, closed: boolean) {
    this.day = day;
    this.start = start;
    this.duration = duration;
    this.closed = closed;
  }

  get toMap() {
    return {
      day: this.day,
      start: this.start,
      duration: this.duration,
      closed: this.closed,
    };
  }

  static fromListData(data: any): OpenDays[] {
    try {
      let temp: OpenDays[] = [];

      for (const oneData of data) {
        temp.push(OpenDays.fromData(oneData));
      }

      return temp;
    } catch (e) {
      console.log(`[OpenDays Model] fromListData e: ${e}`);
      return [];
    }
  }

  static fromData(data: any): OpenDays {
    try {
      const day = FirebaseTypeChange.stringFromData(data["day"]);
      const start = FirebaseTypeChange.stringFromData(data["start"]);
      const duration = FirebaseTypeChange.numberFromData(data["duration"]);
      const closed = FirebaseTypeChange.booleanFromData(data["closed"]);

      return new OpenDays(day, start, duration, closed);
    } catch (error) {
      console.log(`[OpenDayGame Model] fromData e: ${error}`);
      return OpenDays.empty;
    }
  }

  static get empty() {
    return new OpenDays("", "00:00", 0, false);
  }
  static get emptyList() {
    return Array.from(dayList.map((d) => new OpenDays(d, "00:00", 0, false)));
  }
  get clone() {
    return new OpenDays(this.day, this.start, this.duration, this.closed);
  }

  static getBusinessStatus = (businessHours: OpenDays[]): string => {
    const today = new Date();
    let isStatus = "준비중";

    // -6(월) ~ + 7(일) 이 목표 (14번)
    // setDate(0) 하면 전달 마지막 날이 됨.
    // getDay() 0 일요일, 6 토요일
    for (let index = 0; index < businessHours.length * 2; index++) {
      const currentDate = new Date();
      currentDate.setDate(
        currentDate.getDate() - currentDate.getDay() + index - 6
      );

      const startHour = parseInt(
        businessHours[index % businessHours.length].start.split(":")[0]
      );

      const startMinute = parseInt(
        businessHours[index % businessHours.length].start.split(":")[1]
      );
      const startTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        startHour,
        startMinute
      );

      let endTime = startTime;
      if (!businessHours[index % businessHours.length].closed) {
        endTime = new Date(
          startTime.getTime() +
            businessHours[index % businessHours.length].duration *
              60 *
              60 *
              1000
        );
      }

      if (today >= startTime && today < endTime) {
        isStatus = "영업중";
        break;
      } else {
        if (
          index > businessHours.length &&
          businessHours[index % businessHours.length].closed &&
          businessHours[index % businessHours.length].day ===
            dayList[today.getDay()]
        ) {
          isStatus = "휴무";
          break;
        } else {
          isStatus = "준비중";
        }
      }
    }
    return isStatus;
  };
  static getEndString(startTime: string, hDuration: number): string {
    const startHour = parseInt(startTime.split(":")[0]);
    const startMinute = parseInt(startTime.split(":")[1]);

    return `${((Number(startHour) + hDuration) % 24)
      .toString()
      .padStart(2, "0")}:${startMinute.toString().padStart(2, "0")}`;
  }

  static getOpenTimeString = (businessHours: OpenDays, i: number): string => {
    if (businessHours.duration === 0 || businessHours.start === "")
      return `시간정보를 업데이트 해주세요`;
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const daysToMonday =
      currentDay === i
        ? 0
        : currentDay > i
        ? -(currentDay - i)
        : i - currentDay;

    currentDate.setDate(currentDate.getDate() + daysToMonday);

    const startHour = parseInt(businessHours.start.split(":")[0]);
    const startMinute = parseInt(businessHours.start.split(":")[1]);
    const startTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      startHour,
      startMinute
    );

    const durationMilliseconds = businessHours.duration * 60 * 60 * 1000;
    const endTime = new Date(startTime.getTime() + durationMilliseconds);

    const formattedStartTime = startTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedEndTime = endTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${formattedStartTime} ~ ${formattedEndTime}`;
  };
}
