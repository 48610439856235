import { TournamentInfo } from "../../domain/tournament/TournamentInfo.model";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export class FirebaseTournament {
  static fetchTournamentInfo = async (
    // pubId: string,
    tId: string
  ): Promise<TournamentInfo | null> => {
    const firestore = firebase.firestore();

    try {
      const tDoc = firestore.collection("wwp_tournaments").doc(tId);
      const tData = await tDoc.get();
      if (tData.exists) {
        return TournamentInfo.fromData(tData.data());
      }
      return null;
    } catch (e) {
      console.log(`[FirebaseTournament] fetchTournamentInfo error : ${e}`);
      return null;
    }
  };

  static fetchWholeTournaments = async (
    pubId: string
  ): Promise<TournamentInfo[]> => {
    const firestore = firebase.firestore();
    try {
      const tsCol = firestore
        .collection("wwp_tournaments")
        .where("pubId", "==", pubId)
        .where("isPrivate", "==", false);

      let tempList: TournamentInfo[] = [];
      const tournasData = await tsCol.get();
      for (var oneDoc of tournasData.docs) {
        tempList.push(TournamentInfo.fromData(oneDoc.data()));
      }

      return tempList;
    } catch (e) {
      console.log(`[FirebaseTournament] fetchWholeTournaments error : ${e}`);
      return [];
    }
  };
}
