import { FirebaseTypeChange } from "src/domain/utils/FirebaseTypeChange";

export class PlayerRank {
  readonly id: string;
  name: string;
  ranking: number;
  score: number;

  constructor(id: string, name: string, ranking: number, score: number) {
    this.id = id;
    this.name = name;
    this.ranking = ranking;
    this.score = score;
  }

  static fromData(data: any): PlayerRank {
    try {
      const id: string = FirebaseTypeChange.stringFromData(data["id"]);
      const name: string = FirebaseTypeChange.stringFromData(data["name"]);
      const ranking: number = FirebaseTypeChange.numberFromData(
        data["ranking"]
      );
      const score: number = FirebaseTypeChange.numberFromData(data["score"]);

      return new PlayerRank(id, name, ranking, score);
    } catch (error) {
      console.log(`[TournamentResult] fromData e:${error}`);
      return PlayerRank.empty;
    }
  }

  get toMap() {
    return {
      id: this.id,
      name: this.name,
      ranking: this.ranking,
      score: this.score,
    };
  }

  static get empty() {
    return new PlayerRank("", "", 0, 0);
  }
}
