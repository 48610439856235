import { FirebaseTypeChange } from "../utils/FirebaseTypeChange";

export class TournamentSkinData {
  titleColor: string;
  subTitleColor: string;
  totalTimeColor: string;
  timeToBreakTimeColor: string;
  timeToReEntryTime: string;
  timeToReEntryLevel: string;
  playerColor: string;
  rebuyColor: string;
  addOnColor: string;
  prizeStructureColor: string;
  remainTimeColor: string;
  levelColor: string;
  blindColor: string;
  anteColor: string;
  nextBlindColor: string;
  averageStackColor: string;
  totalChipsColor: string;
  levelButtonColor: string;
  backGroundImgUrl: string;
  blackTransparentFilter: boolean;

  constructor(
    titleColor: string,
    subTitleColor: string,
    totalTimeColor: string,
    timeToBreakTimeColor: string,
    timeToReEntryTime: string,
    timeToReEntryLevel: string,
    playerColor: string,
    rebuyColor: string,
    addOnColor: string,
    prizeStructureColor: string,
    remainTimeColor: string,
    levelColor: string,
    blindColor: string,
    anteColor: string,
    nextBlindColor: string,
    averageStackColor: string,
    totalChipsColor: string,
    levelButtonColor: string,
    backGroundImgUrl: string,
    blackTransparentFilter: boolean
  ) {
    this.titleColor = titleColor;
    this.subTitleColor = subTitleColor;
    this.totalTimeColor = totalTimeColor;
    this.timeToBreakTimeColor = timeToBreakTimeColor;
    this.timeToReEntryTime = timeToReEntryTime;
    this.timeToReEntryLevel = timeToReEntryLevel;
    this.playerColor = playerColor;
    this.rebuyColor = rebuyColor;
    this.addOnColor = addOnColor;
    this.prizeStructureColor = prizeStructureColor;
    this.remainTimeColor = remainTimeColor;
    this.levelColor = levelColor;
    this.blindColor = blindColor;
    this.anteColor = anteColor;
    this.nextBlindColor = nextBlindColor;
    this.averageStackColor = averageStackColor;
    this.totalChipsColor = totalChipsColor;
    this.levelButtonColor = levelButtonColor;
    this.backGroundImgUrl = backGroundImgUrl;
    this.blackTransparentFilter = blackTransparentFilter;
  }

  static fromData(data: any): TournamentSkinData {
    try {
      const titleColor: string = FirebaseTypeChange.stringFromData(
        data["titleColor"]
      );
      const subTitleColor: string = FirebaseTypeChange.stringFromData(
        data["subTitleColor"]
      );
      const totalTimeColor: string = FirebaseTypeChange.stringFromData(
        data["totalTimeColor"]
      );
      const timeToBreakTimeColor: string = FirebaseTypeChange.stringFromData(
        data["timeToBreakTimeColor"]
      );
      const timeToReEntryTime: string = FirebaseTypeChange.stringFromData(
        data["timeToReEntryTime"]
      );
      const timeToReEntryLevel: string = FirebaseTypeChange.stringFromData(
        data["timeToReEntryLevel"]
      );
      const playerColor: string = FirebaseTypeChange.stringFromData(
        data["playerColor"]
      );
      const rebuyColor: string = FirebaseTypeChange.stringFromData(
        data["rebuyColor"]
      );
      const addOnColor: string = FirebaseTypeChange.stringFromData(
        data["addOnColor"]
      );
      const prizeStructureColor: string = FirebaseTypeChange.stringFromData(
        data["prizeStructureColor"]
      );
      const remainTimeColor: string = FirebaseTypeChange.stringFromData(
        data["remainTimeColor"]
      );
      const levelColor: string = FirebaseTypeChange.stringFromData(
        data["levelColor"]
      );
      const blindColor: string = FirebaseTypeChange.stringFromData(
        data["blindColor"]
      );
      const anteColor: string = FirebaseTypeChange.stringFromData(
        data["anteColor"]
      );
      const nextBlindColor: string = FirebaseTypeChange.stringFromData(
        data["nextBlindColor"]
      );
      const averageStackColor: string = FirebaseTypeChange.stringFromData(
        data["averageStackColor"]
      );
      const totalChipsColor: string = FirebaseTypeChange.stringFromData(
        data["totalChipsColor"]
      );
      const levelButtonColor: string = FirebaseTypeChange.stringFromData(
        data["levelButtonColor"]
      );
      const backGroundImgUrl: string = FirebaseTypeChange.stringFromData(
        data["backGroundImgUrl"]
      );
      const blackTransparentFilter: boolean =
        FirebaseTypeChange.booleanFromData(data["blackTransparentFilter"]);

      return new TournamentSkinData(
        titleColor,
        subTitleColor,
        totalTimeColor,
        timeToBreakTimeColor,
        timeToReEntryTime,
        timeToReEntryLevel,
        playerColor,
        rebuyColor,
        addOnColor,
        prizeStructureColor,
        remainTimeColor,
        levelColor,
        blindColor,
        anteColor,
        nextBlindColor,
        averageStackColor,
        totalChipsColor,
        levelButtonColor,
        backGroundImgUrl,
        blackTransparentFilter
      );
    } catch (error) {
      console.log(`[TournamentSkinData] fromData e:${error}`);
      return TournamentSkinData.basic;
    }
  }

  get toMap() {
    return {
      titleColor: this.titleColor,
      subTitleColor: this.subTitleColor,
      totalTimeColor: this.totalTimeColor,
      timeToBreakTimeColor: this.timeToBreakTimeColor,
      timeToReEntryTime: this.timeToReEntryTime,
      timeToReEntryLevel: this.timeToReEntryLevel,
      playerColor: this.playerColor,
      rebuyColor: this.rebuyColor,
      addOnColor: this.addOnColor,
      prizeStructureColor: this.prizeStructureColor,
      remainTimeColor: this.remainTimeColor,
      levelColor: this.levelColor,
      blindColor: this.blindColor,
      anteColor: this.anteColor,
      nextBlindColor: this.nextBlindColor,
      averageStackColor: this.averageStackColor,
      totalChipsColor: this.totalChipsColor,
      levelButtonColor: this.levelButtonColor,
      backGroundImgUrl: this.backGroundImgUrl,
      blackTransparentFilter: this.blackTransparentFilter,
    };
  }

  static toKoreanWord(field: string): string {
    switch (field) {
      case "titleColor":
        return "제목";
      case "subTitleColor":
        return "부제목";
      case "totalTimeColor":
        return "Total Time";
      case "timeToBreakTimeColor":
        return "Time to Break";
      case "timeToReEntryTime":
        return "Late Reg";
      case "playerColor":
        return "Player";
      case "reEntryColor":
        return "reEntry";
      case "rebuyColor":
        return "Rebuy";
      case "addOnColor":
        return "AddOn";
      case "prizeStructureColor":
        return "상금";
      case "remainTimeColor":
        return "메인 시간";
      case "levelColor":
        return "레벨";
      case "blindColor":
        return "Blind";
      case "anteColor":
        return "Ante";
      case "nextBlindColor":
        return "Next Blind";
      case "averageStackColor":
        return "Avg. Stack";
      case "totalChipsColor":
        return "Total Chips";
      case "levelButtonColor":
        return "LV 조절";
    }
    return "-";
  }

  static get basic() {
    return new TournamentSkinData(
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      "#000000ff",
      false
    );
  }

  get clone() {
    return new TournamentSkinData(
      this.titleColor,
      this.subTitleColor,
      this.totalTimeColor,
      this.timeToBreakTimeColor,
      this.timeToReEntryTime,
      this.timeToReEntryLevel,
      this.playerColor,
      this.rebuyColor,
      this.addOnColor,
      this.prizeStructureColor,
      this.remainTimeColor,
      this.levelColor,
      this.blindColor,
      this.anteColor,
      this.nextBlindColor,
      this.averageStackColor,
      this.totalChipsColor,
      this.levelButtonColor,
      this.backGroundImgUrl,
      this.blackTransparentFilter
    );
  }
}
